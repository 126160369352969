.title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 25px;
    color: #000000;
    background: #e2e2e2;
    padding: 20px;
    margin: 0;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    letter-spacing: 1px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    .title {
      font-size: 22px;
      padding: 15px;
    }
  }
  @media (max-width: 480px) {
    .title {
      font-size: 20px;
      padding: 10px;
    }
  }