.card-container {
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 6px;
}
.card-container:hover {
  transform: translateY(-2.5px) scale(1.05);
  z-index: 10;
  filter: brightness(1.05);
}
.card-container .alert {
  background: linear-gradient(135deg, #d1eaff, #a1c8ff);
  color: #333333;
  border: none;
  border-radius: 12px;
  padding: 0;
  height: 238px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: none;
  transition: background 0.3s ease;
}
.card-container:hover .alert {
  background: linear-gradient(135deg, #a1c8ff, #7bb8ff);
}
.card-container .alert div {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 500;
  color: #333333;
}

.card-container .alert .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.card-container .alert .subtitle {
  font-size: 14px;
  color: #777777;
  margin-bottom: 15px;
}

.card-container .alert .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.dashboard-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: #000000;
  background: #e2e2e2;
  padding: 20px;
  margin: 0;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  letter-spacing: 1px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .dashboard-title {
    font-size: 22px;
    padding: 15px;
  }
}
@media (max-width: 480px) {
  .dashboard-title {
    font-size: 20px;
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .card-container .alert {
    padding: 15px;
    height: auto;
  }
}
