.title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 25px;
    color: #000000;
    background: #e2e2e2;
    padding: 20px;
    margin: 0;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    letter-spacing: 1px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    .title {
      font-size: 22px;
      padding: 15px;
    }
  }
  @media (max-width: 480px) {
    .title {
      font-size: 20px;
      padding: 10px;
    }
  }

  .MiniCard {
    background-color: #ffffff;
    border-radius: 20px;
    margin: 6px;
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif;
    font-size: 15.5px;
    font-weight: 500;
    color: #444;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .MiniCard:hover {
    background-color: #e3f2fd;
    transform: translateY(-6px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .MiniCard img,
  .MiniCard svg {
    transition: transform 0.3s ease;
  }
  .MiniCard:hover img,
  .MiniCard:hover svg {
    transform: scale(1.15);
  }
  
  .alert-primary {
    color: #084298;
    background-color: #aeceff;
    border-color: #b6d4fe;
}

.alert-success {
  color: #0f5132;
  background-color: #a8eece;
  border-color: #badbcc;
}

.alert-secondary {
  color: #41464b;
  background-color: #cbcccd;
  border-color: #d3d6d8;
}

.alert-danger {
  color: #842029;
  background-color: #f5bdc2;
  border-color: #f5c2c7;
}

.alert-warning {
  color: #664d03;
  background-color: #ffeaa7;
  border-color: #ffecb5;
}

.alert-info {
  color: #055160;
  background-color: #a1e7f7;
  border-color: #b6effb;
}


.Cards {
  background-color: #ffffff;
  border-radius: 12px;
  /* text-align: center; */
  justify-content: space-between;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #444;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.Cards:hover {
  background-color: #e3f2fd;
  transform: translateY(-6px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.Cards img,
.Cards svg {
  transition: transform 0.3s ease;
}

.Cards:hover img,
.Cards:hover svg {
  transform: scale(1.15);
}

.versionSummary {
  margin-top: 0.5rem;
}