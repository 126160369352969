.menu-container {
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 6%;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}
.menu-button.active {
  background-color: #007bff;
  color: white;
}
.menu-button:hover {
  background-color: #b2b2b2;
  transform: scale(1.1);
}
.logout-button {
  color: #ffffff;
  font-weight: bold;
  background-color: #dc3545;
  position: absolute;
  right: 10px;
  border: 2px solid #dc3545;
  border-radius: 5px;
  /* padding: 4px 10px; */
}
.logout-button:hover {
  background-color: #dc3545;
  transform: scale(1.1);
}
.user-details {
  color: #000000;
  font-size: 22px;
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .menu-container {
    padding: 10px;
  }
  .menu-button {
    font-size: 16px;
    margin: 0 5px;
  }
  .logout-button {
    font-size: 14px;
    right: 5px;
    padding: 4px 8px;
  }

  .user-details {
    font-size: 18px;
    top: 5px;
    right: 10px;
  }
}
@media (max-width: 480px) {
  .menu-container {
    flex-direction: column;
    padding: 10px 15px;
  }

  .menu-button {
    font-size: 14px;
    margin: 5px 0;
  }

  .logout-button {
    font-size: 12px;
    right: 5px;
    padding: 4px 6px;
  }

  .user-details {
    font-size: 16px;
    top: 8px;
    right: 5px;
  }
}
